import { useMutation } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FORGET_PASSWORD } from "../../graphql/modules";
import { LOGIN, onRediret } from "../../store/modules";
import { successNotify, USER_ID, warnNotify } from "../../util";
import { Button } from "../Shared";

const ForgotPassword = () => {
  // state declare
  const [email, setEmail] = useState("");
  const [disableAfterSend, setDisableAfterSend] = useState(false);
  // dispatch initialize
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(onRediret({ type: LOGIN }));
  };

  // call api to send email
  const [onSendEmail, { loading }] = useMutation(FORGET_PASSWORD);
  const handleSubmit = async () => {
    try {
      const {
        data: { ForgetPassword },
      } = await onSendEmail({
        variables: {
          email,
        },
      });
      if (ForgetPassword.success) {
        successNotify(ForgetPassword.message);
        // setEmail('')
        setDisableAfterSend(true);
      } else {
        warnNotify(ForgetPassword.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const disable = !email;
  return (
    <div>
      {/* <div class="location-previous"> */}
      {/* <button  class="btn_previous_icon">
        Go to Login
      </button> */}
      <div className="reset-password-container mb-5">
        <div className="reset-pass-inner">
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "0",
              textDecoration: "underline",
              textDecorationColor: "#3d3d3d",
              color: "#3d3d3d",
            }}
            onClick={handleBack}
            id={USER_ID}
          >
            Return to login
          </button>
          <div className="reset-title-sub">
            <h5 className="mt-2">Reset Password</h5>
            <p>
              Forgot your password? Please enter the email address that you used
              when creating your account.
            </p>
          </div>
          <hr />
          <div className="reset-content-form">
            <form action="">
              <div className="mb-3">
                <label htmlFor="email" className="form-label" style={{color:"#3d3d3d"}}>
                  Email*
                </label>
                <input
                  type="email"
                  className="form-control cmn-control"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@chillfitrave.com"
                  style={{color:"#3d3d3d"}}
                />
              </div>
              <Button
                btnText={`${disableAfterSend ? "Sent" : "Send"}`}
                onClick={handleSubmit}
                loading={loading}
                disabled={disable || disableAfterSend}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
