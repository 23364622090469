import productType from "../constants/productType";

export const UpdateCartProduct = (previousItem = [], updatedItem = []) => {
  let cartItem = [],
    preItem = [];
  previousItem.forEach((prev) => {
    updatedItem.forEach((element) => {
      if (prev?._id === element?._id) {
        if (element?.status === "active") {
          if (element?.isAccessories) {
            const styleImage = prev?.style
              ? element?.styles?.find((st) => st?.name === prev?.style)?.url
              : "";
            if (element?.quantity >= prev.quantity) {
              cartItem.push({
                ...prev,
                ...element,
                gallery: styleImage ? [styleImage] : element?.gallery,
                quantity: prev?.quantity || 1,
                price: element?.price,
              });
            } else {
              if (element?.quantity > 0) {
                cartItem.push({
                  ...prev,
                  ...element,
                  quantity: 1,
                  gallery: styleImage ? [styleImage] : element?.gallery,
                  price: element?.price,
                });
              } else {
                preItem.push({
                  ...prev,
                  ...element,
                  gallery: styleImage ? [styleImage] : element?.gallery,
                  quantity: prev?.quantity || 1,
                  price: element?.price,
                });
              }
            }
          } else {
            const {
              bottomSizing: elementBottomSizing,
              topSizing: elementTopSizing,
            } = element.colors.find(
              (color) => color?._id === prev?.colorDetails?._id
            );
            const bottomSizing = findSize(
              prev?.bottomName,
              elementBottomSizing
            );
            const topSizing = findSize(prev?.topName, elementTopSizing);
            if (
              (prev.part === productType.fullSet &&
                bottomSizing?.quantity >= prev?.quantity &&
                topSizing?.quantity >= prev?.quantity) ||
              (prev.part === productType.top &&
                topSizing?.quantity >= prev?.quantity) ||
              (prev.part === productType.bottom &&
                bottomSizing?.quantity >= prev?.quantity)
            ) {
              cartItem.push({
                ...prev,
                ...element,
                price: element?.price,
                bottomSizing: prev?.bottomSizing,
                quantity: prev?.quantity || 1,
                topSizing: prev?.topSizing,
                gallery: prev?.gallery,
              });
            } else {
              if (
                (prev.part === productType.fullSet &&
                  bottomSizing?.quantity > 0 &&
                  topSizing?.quantity > 0) ||
                (prev.part === productType.top && topSizing?.quantity > 0) ||
                (prev?.part === productType.bottom &&
                  bottomSizing?.quantity > 0)
              ) {
                cartItem.push({
                  ...prev,
                  ...element,
                  price: element?.price,
                  bottomSizing: prev?.bottomSizing,
                  quantity: 1,
                  topSizing: prev?.topSizing,
                  gallery: prev?.gallery,
                });
              } else {
                preItem.push({
                  ...prev,
                  ...element,
                  price: element?.price,
                  bottomSizing: prev?.bottomSizing,
                  topSizing: prev?.topSizing,
                  quantity: prev?.quantity || 1,
                  gallery: prev?.gallery,
                });
              }
            }
          }
        }
      }
    });
  });
  return { preItem, cartItem };
};

const findSize = (sizeName, sizeList = []) => {
  var size = sizeList?.find((item) => sizeName === item?.size?.name);
  return size;
};

export const FindProductAvailSize = (cartProducts, item) => {
  let product;
  cartProducts.map((prod) => {
    if (item.isAccessories && item._id === prod._id) {
      product = prod;
      if (item?.style) {
        product.quantity = item?.styles?.find(
          (st) => st.name === item?.style
        )?.quantity;
      }
    } else if (item._id === prod._id) {
      const colorDetails = prod?.colors?.find(
        (cl) => cl?._id === item?.colorDetails?._id
      );
      if (item?.part === productType.fullSet) {
        product = {
          ...prod,
          quantity: Math.min(
            colorDetails?.bottomSizing?.find(
              (data) => data?.size?._id === item?.bottomSizing?.size?._id
            )?.quantity,
            colorDetails?.topSizing?.find(
              (data) => data?.size?._id === item?.topSizing?.size?._id
            )?.quantity
          ),
        };
      } else if (item?.part === productType.bottom) {
        product = {
          ...prod,
          quantity: colorDetails?.bottomSizing?.find(
            (data) => data?.size?._id === item?.bottomSizing?.size?._id
          )?.quantity,
        };
      } else if (item?.part === productType.top) {
        product = {
          ...prod,
          quantity: colorDetails?.topSizing?.find(
            (data) => data?.size?._id === item?.topSizing?.size?._id
          )?.quantity,
        };
      }
    }
  });
  return product;
};

export const FindSelectedProductAvailSize = (prod, item) => {
  let product;
  if (item.isAccessories && item._id === prod._id) {
    product = prod;
    if (item?.style) {
      product.quantity = item?.styles?.find(
        (st) => st.name === item?.style
      )?.quantity;
    }
  } else if (item._id === prod._id) {
    const colorDetails = prod?.colors?.find(
      (cl) => cl?._id === item?.colorDetails?._id
    );
    if (item?.part === productType.fullSet) {
      product = {
        ...prod,
        quantity: Math.min(
          colorDetails?.bottomSizing?.find(
            (data) => data?.size?._id === item?.bottomSizing?.size?._id
          )?.quantity,
          colorDetails?.topSizing?.find(
            (data) => data?.size?._id === item?.topSizing?.size?._id
          )?.quantity
        ),
      };
    } else if (item?.part === productType.bottom) {
      product = {
        ...prod,
        quantity: colorDetails?.bottomSizing?.find(
          (data) => data?.size?._id === item?.bottomSizing?.size?._id
        )?.quantity,
      };
    } else if (item?.part === productType.top) {
      product = {
        ...prod,
        quantity: colorDetails?.topSizing?.find(
          (data) => data?.size?._id === item?.topSizing?.size?._id
        )?.quantity,
      };
    }
  }
  return product;
};

export const CheckSizeAvailable = (cartItem = [], product = {}) => {
  let topCnt = 0;
  let bottomCnt = 0;
  cartItem.forEach((element) => {
    if (
      element?._id === product?._id &&
      element?.colorDetails?._id === product?.colorDetails?._id
    ) {
      if (
        element?.topSizing &&
        element?.topSizing?.size?._id === product?.topSizing?.size?._id
      ) {
        topCnt += element?.quantity;
      }
      if (
        element?.bottomSizing &&
        element?.bottomSizing?.size?._id === product?.bottomSizing?.size?._id
      ) {
        bottomCnt += element?.quantity;
      }
    }
  });
  return { topCnt, bottomCnt };
};

export const CheckSelectedSizeAvailable = (element, product = {}) => {
  let topCnt = 0;
  let bottomCnt = 0;

  if (
    element?._id === product?._id &&
    element?.colorDetails?._id === product?.colorDetails?._id
  ) {
    if (
      element?.topSizing &&
      element?.topSizing?.size?._id === product?.topSizing?.size?._id
    ) {
      topCnt += element?.quantity;
    }
    if (
      element?.bottomSizing &&
      element?.bottomSizing?.size?._id === product?.bottomSizing?.size?._id
    ) {
      bottomCnt += element?.quantity;
    }
  }

  return { topCnt, bottomCnt };
};
