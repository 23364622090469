import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import {
  APP_SETTINGS_QUERY,
  MAINTENANCE_SUBSCRIPTION,
  FETCH_ABOUT_CATEGORY,
} from "../../graphql/modules";
import { MENU_DRAWER, onSetDrawer } from "../../store";
import Container from "./Container";
import DrawerRight from "./Drawer/DrawerRight";
import DrawerSearch from "./Drawer/DrawerSearch";
import DrawerShopping from "./Drawer/DrawerShopping";
import DrawerSubscribe from "./Drawer/DrawerSubscribe";
import DrawerLeft from "./Drawer/DrwerLeft";
import Footer from "./Footer";
import Header from "./Header";
import { ADMIN_EMAIL } from "../../config/constants";
import Announcement from "./Announcement";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const allDrawer = useSelector((state) => state?.allDrawer);
  const { user } = useSelector((state) => state?.auth);

  const location = useLocation();
  const [shoppingClassName, setShoppingClassName] = useState(false);
  const [menuDrawerClassName, setMenuDrawerClassName] = useState(false);
  const [userDrawerClassName, setUserDrawerClassName] = useState(false);
  const [subscribDrawerClassName, setSubscribeDrawerClassName] =
    useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const wrapRefShopping = useRef(null);
  const wrapRefUser = useRef(null);
  const wrapRefSubscriber = useRef(null);
  const wrapRefMenu = useRef(null);
  const wrapButtonUser = useRef(null);
  const wrapButtonMenu = useRef(null);
  const wrapButtonShopping = useRef(null);
  const wrapButtonSubscriber = useRef(null);

  // handle shopping cart drawer
  const openShoppingCart = () => {
    setShoppingClassName(!shoppingClassName);
    dispatch(dispatch(onSetDrawer(MENU_DRAWER, true, !allDrawer[MENU_DRAWER])));
  };

  const openSubscribe = () => {
    setSubscribeDrawerClassName(!subscribDrawerClassName);
    dispatch(dispatch(onSetDrawer(MENU_DRAWER, true, !allDrawer[MENU_DRAWER])));
  };

  // menu drawer handle
  const openMenuDrawer = () => {
    setMenuDrawerClassName(!menuDrawerClassName);
    dispatch(dispatch(onSetDrawer(MENU_DRAWER, true, !allDrawer[MENU_DRAWER])));
  };
  // user drawer handle
  const openUserDrawer = () => {
    //   setShoppingClassName(false);
    // setMenuDrawerClassName(false);
    setUserDrawerClassName(!userDrawerClassName);
    dispatch(dispatch(onSetDrawer(MENU_DRAWER, true, !allDrawer[MENU_DRAWER])));
  };
  // search bar handle
  const openSearchBar = () => {
    setShoppingClassName(false);
    setMenuDrawerClassName(false);
    setUserDrawerClassName(false);
    setSearchBar(!searchBar);
    dispatch(dispatch(onSetDrawer(MENU_DRAWER, true, !allDrawer[MENU_DRAWER])));
  };

  // const [GetCategory, { data: ctData, refetch: ctRefetch }] = useLazyQuery(
  //   FETCH_CATEGORY,
  //   {
  //     fetchPolicy: "no-cache",
  //     // onCompleted: () => ctRefetch(),
  //   }
  // );
  // const categoryList = ctData?.FetchCategoryasdasdf?.result || [];
  // // const ct = useSelector((state) => state.categories);
  // console.log(categoryList);
  // //  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(saveCategory(categoryList));
  //   GetCategory();
  // }, []);

  // useEffect(() => {
  //   dispatch(saveCategory(categoryList));
  // }, [categoryList]);

  useEffect(() => {
    handleCloseOuterClick(shoppingClassName);
  }, [shoppingClassName]);
  useEffect(() => {
    handleCloseOuterClickMenu(menuDrawerClassName);
  }, [menuDrawerClassName]);

  useEffect(() => {
    handleCloseOuterClickUser(userDrawerClassName);
  }, [userDrawerClassName]);
  useEffect(() => {
    handleCloseOuterClickSubscriber(subscribDrawerClassName);
  }, [subscribDrawerClassName]);

  useEffect(() => {
    if (
      shoppingClassName ||
      userDrawerClassName ||
      menuDrawerClassName ||
      subscribDrawerClassName
    ) {
      window.document.body.style.overflowY = "scroll";
      window.document.body.style.position = "fixed";
      window.document.body.style.top = "0";
      window.document.body.style.left = "0";
      window.document.body.style.right = "0";
    } else {
      window.document.body.style.position = "static";
      window.document.body.style.overflowY = "scroll";
    }
  }, [
    userDrawerClassName,
    menuDrawerClassName,
    shoppingClassName,
    subscribDrawerClassName,
  ]);

  const search = location.search;
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  useEffect(() => {
    setShoppingClassName(false);
    setMenuDrawerClassName(false);
    setUserDrawerClassName(false);
  }, [location.pathname, tab]);

  const handleCloseOuterClick = (condition) => {
    if (condition && !userDrawerClassName && !menuDrawerClassName) {
      function handleClickOutside(event) {
        if (
          wrapRefShopping.current &&
          !wrapRefShopping.current?.contains(event.target) &&
          !wrapButtonShopping.current?.contains(event.target)
        ) {
          setShoppingClassName(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    // Bind the event listener
  };

  const handleCloseOuterClickSubscriber = (condition) => {
    if (
      condition &&
      !userDrawerClassName &&
      !menuDrawerClassName &&
      !shoppingClassName
    ) {
      function handleClickOutside(event) {
        if (
          wrapRefSubscriber.current &&
          !wrapRefSubscriber.current?.contains(event.target) &&
          !wrapButtonSubscriber.current?.contains(event.target)
        ) {
          setSubscribeDrawerClassName(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    // Bind the event listener
  };

  const handleCloseOuterClickMenu = (condition) => {
    if (condition && !userDrawerClassName && !shoppingClassName) {
      function handleClickOutside(event) {
        if (
          wrapRefMenu.current &&
          !wrapRefMenu.current?.contains(event.target) &&
          !wrapButtonMenu.current?.contains(event.target)
        ) {
          setMenuDrawerClassName(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }

    // Bind the event listener
  };

  const handleCloseOuterClickUser = (condition) => {
    if (condition) {
      function handleClickOutside(event) {
        if (
          wrapRefUser.current &&
          !wrapRefUser.current?.contains(event.target) &&
          !wrapButtonUser.current?.contains(event.target)
        ) {
          setUserDrawerClassName(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    // Bind the event listener
  };
  const { data: aboutCategoryData } = useQuery(FETCH_ABOUT_CATEGORY);
  const aboutCategory = aboutCategoryData?.FetchAbout?.result || [];

  const { data, refetch, subscribeToMore, loading } =
    useQuery(APP_SETTINGS_QUERY);
  const isInMaintenance =
    user?.email !== ADMIN_EMAIL && data?.FetchAppSettings?.isInMaintenance;

  const menuShowMode = data?.FetchAppSettings?.isImagedCategory;

  const fetchMore = () => {
    subscribeToMore({
      document: MAINTENANCE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch();
      },
    });
  };
  useEffect(() => {
    fetchMore();
  }, []);
  if (loading) return <p></p>;
  // console.log(
  //   "aboutSubmenu, aboutCategoryData ======================> ",
  //   aboutCategoryData,
  //   aboutCategory
  // );
  console.log(location.pathname);
  // if (location.pathname === "/return-order") return <p></p>;
  return (
    <div>
      <Announcement />
      {location.pathname !== "/return-order" && (
        <>
          <Header
            openShoppingCart={openShoppingCart}
            openMenuDrawer={openMenuDrawer}
            openUserDrawer={openUserDrawer}
            shoppingClassName={shoppingClassName}
            menuDrawerClassName={menuDrawerClassName}
            userDrawerClassName={userDrawerClassName}
            openSearchBar={openSearchBar}
            wrapButtonUser={wrapButtonUser}
            wrapButtonMenu={wrapButtonMenu}
            wrapButtonShopping={wrapButtonShopping}
            isInMaintenance={isInMaintenance}
            menuShowMode={menuShowMode}
            aboutCategory={aboutCategory}
          />
          <DrawerLeft
            wrapRefMenu={wrapRefMenu}
            openMenuDrawer={openMenuDrawer}
            menuDrawerClassName={menuDrawerClassName}
            aboutCategory={aboutCategory}
          />
          <DrawerRight
            wrapRefUser={wrapRefUser}
            wrapButtonUser={wrapButtonUser}
            userDrawerClassName={userDrawerClassName}
          />
          <DrawerSearch searchBar={searchBar} openSearchBar={openSearchBar} />
          <DrawerShopping
            wrapRefShopping={wrapRefShopping}
            shoppingClassName={shoppingClassName}
            openShoppingCart={openShoppingCart}
          />
          <DrawerSubscribe
            openSubscribe={openSubscribe}
            subscribDrawerClassName={subscribDrawerClassName}
            wrapRefSubscriber={wrapRefSubscriber}
            setSubscribeDrawerClassName={setSubscribeDrawerClassName}
          />
        </>
      )}
      {isInMaintenance ? (
        <div className="container">
          <div style={{ minHeight: "calc(100vh - 152px)" }}>
            <div style={{ maxWidth: "415px", textAlign: "", margin: "auto" }}>
              <h2
                style={{ fontSize: "20px", fontWeight: "700" }}
                className="mb-3 mt-5"
              >
                We’ll be back soon!
              </h2>
              <p style={paraStyle} className="mb-3">
                Sorry for the inconvenience but we’re performing some
                maintenance at the moment. If you need you can always contact
                us, otherwise we’ll be back shortly!
              </p>
              <p style={paraStyle} className="mb-3">
                To contact us please email us at{" "}
                <a
                  href="mailto:help@chillfitrave.com"
                  style={{ color: "#007AFE" }}
                >
                  help@chillfitrave.com
                </a>
                . We will respond to you within 24 hours, from Monday to Friday.
              </p>
              <p style={paraStyle} className="mb-3">
                — The Chillfit Team
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Container>{children}</Container>
      )}
      <Footer
        isInMaintenance={isInMaintenance}
        openSubscribe={openSubscribe}
        wrapButtonSubscriber={wrapButtonSubscriber}
        aboutCategory={aboutCategory}
      />
    </div>
  );
};

export default Layout;

const paraStyle = {
  textAlign: "justify",
  fontSize: "14px",
  fontWeight: "400",
  // color: "#007AFE",
};
