import productType from "../constants/productType";

export const totalOrderPrice = (data = [], couponDiscount = 0, cp = false) => {
  let regularProdPrice = 0;
  let discountProdPrice = 0;
  let discountedItems = 0;
  // let bogoPrice = 0;
  // let bogoProduct = {};
  // let exceptBogo = 0;
  // let isFirstBogo = true;
  for (let i = 0; i < data?.length; i++) {
    const product = data[i];
    const initialPrice =
      product?.part === productType.fullSet
        ? product?.price
        : product?.[product?.part?.toLowerCase() + "Price"];
    //previous code
    // let minusBogo = initialPrice;
    if (product.haveDiscount) {
      const price = initialPrice * (product?.quantity || 1);
      discountProdPrice =
        discountProdPrice +
        (price - ((price * product.discount) / 100).toFixed(2));
    } else {
      discountedItems++;
      let totalPrice = initialPrice * (product?.quantity || 1);
      regularProdPrice = regularProdPrice + totalPrice;
    }
  }
  let discountAmount = 0;
  if (couponDiscount > 0) {
    discountAmount = ((regularProdPrice * couponDiscount) / 100).toFixed(2);
    console.log({ discountAmount, regularProdPrice, couponDiscount });
    regularProdPrice = regularProdPrice - discountAmount;
  }
  // console.log("coun count ===== ", discountedItems, data.length);
  if (cp) {
    return [
      discountProdPrice + regularProdPrice,
      discountAmount,
      discountedItems === data.length && couponDiscount,
    ];
  }

  return discountProdPrice + regularProdPrice;
};

export const totalRegularOrderPrice = (data = [], discountSource = []) => {
  [...discountSource].sort((a, b) => b?.amount - a?.amount);
  let couponProductPrice = 0;
  // let bogoPrice = 0;
  // let bogoProduct = {};
  // let exceptBogo = 0;
  for (let i = 0; i < data?.length; i++) {
    const product = data[i];
    // if(product?.catSubId ==='654fe8ae9f80a10014bc8d3b'){
    //   bogoPrice += product?.price * (product?.quantity || 1);
    //   bogoProduct = product
    // } else if (product?.catSubId ==='657548a333794e00143eb25a'){
    //   exceptBogo += product?.price * (product?.quantity || 1);
    // } else {
    //    couponProductPrice =
    //   couponProductPrice + product?.price * (product?.quantity || 1);
    // }

    // if(couponProductPrice>=188){
    //   bogoPrice -= bogoProduct?.price;
    // }

    // couponProductPrice = bogoPrice+exceptBogo;
    //previous code
    couponProductPrice =
      couponProductPrice + product?.price * (product?.quantity || 1);
  }
  let discountAmount = 0;
  for (let i = 0; i < discountSource?.length; i++) {
    if (
      discountSource[i].status === "Active" &&
      discountSource[i].validUntil > Date.now() &&
      discountSource[i].amount <= couponProductPrice
    ) {
      discountAmount = discountSource[i].discountAmount;
      break;
    }
  }
  return discountAmount;
};

const bogoSubCatId = "64515742765db9001444cede";
const exceptBogoSubCatId = "64d6ac2a38b4e1001462d64e";
export const totalOrderPriceForBuyOneGetOne = (data) => {
  let totalPrice = 0;
  let isFirstBogo = true;
  let bogoProdidx = -1;
  for (let i = 0; i < data?.length; i++) {
    const product = data[i];
    const initialPrice =
      product?.part === productType.fullSet
        ? product?.price
        : product?.[product?.part?.toLowerCase() + "Price"];
    if (product?.catSubId === bogoSubCatId && isFirstBogo) {
      isFirstBogo = false;
      bogoProdidx = i;
    }
    // calculate get one product price
    if (
      product?.catSubId !== bogoSubCatId &&
      product?.catSubId !== exceptBogoSubCatId
    ) {
      if (product.haveDiscount) {
        const price = initialPrice * (product?.quantity || 1);
        totalPrice =
          totalPrice + price - ((price * product.discount) / 100).toFixed(2);
      } else {
        totalPrice = totalPrice + initialPrice * (product?.quantity || 1);
      }
    }
  }

  return [totalPrice, bogoProdidx];
};
